import React from 'react'
import lect_septiembre from './files/lecturaseptiembre.pdf'
import lect_oct from './files/lecturaoctubre.pdf'
import lect_noviembre from './files/lecturanoviembre.pdf'
import lect_dic from './files/lecturadiciembre.pdf'
class Planes extends React.Component {
  constructor() {
    super()
    this.state = {
      planes : [
        {
          id: 1,
          month: 'Septiembre',
          url: lect_septiembre
        },
        {
          id: 2,
          month: 'Octubre',
          url: lect_oct,
        },
        {
          id: 3,
          month: 'Noviembre',
          url: lect_noviembre
        },
        {
          id: 4,
          month: 'Diciembre',
          url: lect_dic
        }
      ]
    }
  }
  render() {
    return(
      <>
        <div style={{height: "50px"}}></div>
        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Planes</h2>
              </div>
              <div className="col-md-12">
                {this.state.planes.map( element => (
                  <div className="card shadow-card mt-md-2">
                    <div className="card-body">
                      <h5>{element.month}</h5>
                      <a href={element.url} target="_blank" rel="noopener noreferrer">Ver Plan</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> 
      </>
    )
  }
}

export default Planes
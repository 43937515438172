import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { firebaseConfig } from './shared/config'
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from 'firebase/database'
// Components
import Main from './components/home/index'
import Navbar from './components/navbar'
import Location from './components/home/location'
import Resources from './components/resources/index'
import Articles from './components/articulos/index'
import Planes from './components/resources/planes'
import Nosotros from './components/nosotros/index'
import Store from './components/store/index'
import Bible from './components/bible/index'
import RadioInfo from './components/radio/index'
// Styles
import './fonts/icomoon/style.css'
import './styles/magnific.css'
import './styles/owl.css'
import './styles/owl.theme.default.min.css'
import './styles/datepicker.css'
import './styles/animate.css'
import './fonts/flaticon/font/flaticon.css'
import './styles/aos.css'
import './styles/App.css';

// Initialize Firebase

const app = initializeApp(firebaseConfig)
const db = getDatabase(app)
const reference = ref(db, 'books/')
const ref_settings = ref(db, 'settings/')
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      settings: {},
      data: []
    }
    this.getStore()
    this.getSettings()
  }
  getStore() {
    onValue(reference, (snapshot) => {
      let store = Object.entries(snapshot.val())
      store.sort((a, b) => {
        let number_1 = a[0].replace(/[^0-9]/g, '')
        let number_2 = b[0].replace(/[^0-9]/g, '')
        number_1 = parseInt(number_1)
        number_2 = parseInt(number_2)
        return number_1-number_2
      })
      this.setState({...this.state, data: store })
    })
  }
  getSettings() {
    onValue(ref_settings, (snapshot) => {
      this.setState({...this.state, settings: snapshot.val()})
    })
  }

  render() {
    let year = new Date().getFullYear()
    return(
      <Router>
        <div className="site-wrap">
          <Navbar />
          <Switch>
            <Route exact path="/" render={ (props) => <Main {...props} settings={this.state.settings}/> } />
            <Route path="/recursos" component={Resources} />
            <Route path='/articulos' component={Articles} />
            <Route path='/planes' component={Planes} />
            <Route path='/nosotros' component={Nosotros} />
            <Route path='/tienda' render={ (props) => <Store {...props} data={this.state.data} /> } />
            <Route path='/proyecto-biblia' component={Bible} />
            <Route path="/radio" component={RadioInfo} />
          </Switch>
          <Location year={year} />
        </div>
      </Router>
    )
  }
}

export default App;

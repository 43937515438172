import React from 'react'
import bg from '../../images/cover.jpeg';
const Slider = (props) => (
  <div className="slide-one-item home-slider owl-carousel">
    <div className="site-blocks-cover" style={{backgroundImage: `url(${bg})`}}  data-aos="fade" data-stellar-background-ratio="0.5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12" data-aos="fade">
            <h1 className="slider--title">
              <strong className="slider-text__shadow">{props.month} {props.year}</strong>
            </h1>
            <h2 className="sentence-content slider-text__shadow">{props.settings.phrase}<br/> {props.settings.verse}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default Slider;

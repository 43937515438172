import React from 'react'
import Dotdotdot from 'react-dotdotdot'
export default class Articles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      articles: [
        { name: 'Analizando la Reina Valera 1960', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fespa%C3%B1ol%2F1.pdf?alt=media&token=1c1cdce3-537f-49a3-9c91-58e73e59a2f0', autor: 'Allen Johnson', category: "Español"},
        { name: 'Católicos cambiando el nombre de Dios', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fespa%C3%B1ol%2F2.pdf?alt=media&token=5d5890e2-1322-4625-a6ad-b54016c8df68', autor: 'R.A. Riplinger', category: "Español"},
        { name: 'La Santa Biblia', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fespa%C3%B1ol%2F3.pdf?alt=media&token=9bfdc67c-05d4-46cc-8eef-3dab437496cf', autor: 'Pastor Raúl Reyes', category: "Español"},
        { name: 'El Perfil de las 3 ciudades', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fespa%C3%B1ol%2F4.pdf?alt=media&token=1661beaf-4e8b-4fac-bc8d-d5b0f93bb959', autor: 'David F. Reagan, Th. D.', category: "Español"},
        { name: 'La Biblia Española', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fespa%C3%B1ol%2F5.pdf?alt=media&token=be9a5ec2-52ca-4aff-b1e5-5c8ce377af1c', autor: 'Robert R. Breaker', category: "Español"},
        { name: 'Sólo una Biblia pura producirá un Cristianismo puro', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fespa%C3%B1ol%2F6.pdf?alt=media&token=119aa453-9733-4a7f-8a34-de736f2eb444', autor: 'Carlos A. Rivera', category: "Español"},
        { name: 'Valeras Comparadas', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fespa%C3%B1ol%2FValeras-Comparadas.pdf?alt=media&token=8d04a099-e3e9-423a-94ca-a87beae1a7f9', autor: 'Rex Cobb', category: "Español"},
        { name: 'The Spanish Bible', path: 'http://www.rrb3.com/mypub/books/spn_bible_inception_men_translation.htm', autor: 'Robert R. Breaker', category: "Inglés"},
        { name: 'Valeras Compared', path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fenglish%2FValeras-Compared.pdf?alt=media&token=8dfd641c-192b-4526-87ad-1597ec73c21b', autor: 'Rex Cobb', category: "Inglés"},
        { name: "Testing the Reina Valera 1960", path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fenglish%2FTesting-the-1960.pdf?alt=media&token=1d0af16a-040c-42fd-98c8-c5a7be87dd0d', autor: 'Allen Johnson', category: "Inglés"},
        { name: "Catholics changing God's name", path: 'https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/libros%2Fenglish%2FCatholics-Changing-Gods-Name.pdf?alt=media&token=0e2a9a77-9234-44f4-86cd-1c41a1d92c1e', autor: 'R.A. Riplinger', category: "Inglés"},
        { name: "Las Sociedades Bíblicas Unidas y las traducciones castellanas basadas en la vulgata", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticuloabril2016(biblia).pdf?alt=media&token=2c18b3e2-5757-42f5-a5a9-fbafb7dbcff4", autor: "Carlos Rivera Morales", category: "Español"},
        { name: "La buena comida", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticuloabril2017(biblia).pdf?alt=media&token=9327bf6f-640f-406a-8390-ea9bac6f2364", autor: "Silvestre Vázquez Esqueda", category: "Español"},
        { name: "Exhortación de Cipriano de Valera en su Biblia publicada en 1602", autor: "Desconocido", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticuloagosto2016(biblia).pdf?alt=media&token=68175b40-4fd4-491d-825c-77012aafce38", category: "Español"},
        { name: "Un Resguardo a las Escrituras", autor: "Alejandro Quezada", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulodiciembre2015(biblia).pdf?alt=media&token=77aeeaae-fba7-43ce-8a03-9acb99f3cf5b", category: "Español"},
        { name: "¿Debemos confiar en las sociedades bíblicas modernistas?", autor: "Dr. Phil Stringer", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulodiciembre2016(biblia).pdf?alt=media&token=20250b35-7a65-4be4-9ea2-07d89e25d4d5", category: "Español"},
        { name: "¿Texto Recibido o Texto Crítico?", autor: "Dra. Gail Riplinger", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticuloenero2017(biblia).pdf?alt=media&token=8e0ab144-3aa4-4c55-a411-1ea2308456f2", category: "Español"},
        { name: "Tomando una decisión", autor: "Carlos Rivera", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulofebrero2016(biblia).pdf?alt=media&token=426eec49-ad56-47e7-9569-c41fa0d172d1", category: "Español"},
        { name: "El Elefante en la sala I", autor: "Dr. Mickey Carter", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulofebrero2017(biblia).pdf?alt=media&token=293ad8de-c366-4dac-8200-03521549c46f", category: "Español"},
        { name: "Sumamente pura es tu Palabra", autor: "Carlos Guillén", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulomarzo2016(biblia).pdf?alt=media&token=727e184e-22e6-45d8-b04d-52a7dcf94ff5", category: "Español"},
        { name: "El Elefante en la sala II", autor: "Dr. Mickey Carter", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulomarzo2017(biblia).pdf?alt=media&token=3b657168-f758-4849-aca0-fa2807cf6c34", category: "Español"},
        { name: "La Sana Doctrina", autor: "Juan López", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulomayo2016(biblia).pdf?alt=media&token=8be207f8-925a-4a23-9ea4-c04d8cb60def", category: "Español"},
        { name: "Esta es la verdadera Biblia Valera 1602 Purificada", autor: "Carlos Rivera", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulomayo2017(biblia).pdf?alt=media&token=1ae5a951-e411-4a23-bbe9-8b4493722755", category: "Español"},
        { name: "¿Que Biblia debo usar?", autor: "Valera 1602 Org", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulonoviembre2015(biblia).pdf?alt=media&token=310b9701-d286-44f0-8a31-c1c374e965f5", category: "Español"},
        { name: "Formación del Texto Recibido", autor: "Desconocido", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulonoviembre2016(biblia).pdf?alt=media&token=34365473-035c-4256-bd03-126ccffb26a5", category: "Español"},
        { name: "Por que creo que la Biblia Antigua Valera 1602 es la Palabra de Dios preservada en Castellano", autor: "Carlos Rivera", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulooctubre2015(biblia).pdf?alt=media&token=5e3b975b-8f88-49d3-90d6-49626cdb1a8d", category: "Español"},
        { name: "Exhortación de Cipriano de Valera III", autor: "Desconocido", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticulooctubre2016(biblia).pdf?alt=media&token=13d05b9b-7464-475b-82f8-f2974b67e64e", category: "Español"},
        { name: "¿Jehová o SEÑOR? ¿Cuál debemos usar?", autor: "Robert Breaker III", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticuloseptiembre2015(biblia).pdf?alt=media&token=1c794c4e-e160-41d5-9c2b-9e18d46650ae", category: "Español"},
        { name: "Exhortación de Cipriano de Valera II", autor: "Desconocido", path: "https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/articulos%2Farticuloseptiembre2016(biblia).pdf?alt=media&token=432674a4-5ecc-4f84-aa4e-d05326736821", category: "Español"},
      ],
    }
  }


  render() {
    return(
      <React.Fragment>
        <div style={{height: "50px"}}></div>
        <section className="site-section">
          <div className="container">
            <div className="row">
              {this.state.articles.map((article, id) => (
                <a href={article.path} target="_blank" rel="noopener noreferrer" className="container__articles mb-3 col-md-3">
                  <div className="articles__card">
                    <Dotdotdot clamp={2}>
                      <p>{article.name}</p>
                    </Dotdotdot>
                    <div className="articles__footer">
                      {
                        article.category === "Inglés"
                        ?
                        <div className="articles__footer--category_en">{article.category}</div>
                        :
                        <div className="articles__footer--category_es">{article.category}</div>
                      }
                      <em className="articles__footer--author"><Dotdotdot clamp={1}>Autor: {article.autor}</Dotdotdot></em>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}
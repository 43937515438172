import React from 'react'

const Bible = () => (
  <>
    <div style={{height: '50px'}}></div>
    <div className="site-section">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Historia de la Valera 1602 Purificada</h2>
          </div>
          <div className="col-md-12">
            <p>La Biblia hispana Valera 1602 Purificada (IBBG) es el trabajo de una Iglesia Local, Bautista e Independiente y no sólo de una persona o una Sociedad Bíblica liberal. </p>
            <p>La Iglesia que hizo el trabajo es "La Iglesia Bautista Bíblica de la Gracia" ubicada en Monterrey, México.</p>
            <p>Bajo la autoridad de Pastor Raúl Reyes, la Iglesia empezó el trabajo en 1994, después de mucha oración y ayuno sobre qué hacer acerca de la Biblia hispana.</p>
            <p>
              Habiendo estudiado la 1960, la 1909, y la 1865, y al ver los muchos errores en esas versiones, deseamos una Biblia hispana pura.
              Sin embargo, fuimos cuidadosos en buscar la manera correcta de ir purificando la Biblia española con los textos correctos.
              Vimos la manera equivocada para hacerla, lo cual es darla a una Sociedad Bíblica liberal, porque ellos nunca habían estado interesados en diseminar una Biblia pura en español, más bien sólo revisando versiones ya existentes con textos aún más críticos.
            </p>
            <p>
              También vimos los problemas cuando un hombre que hace el trabajo por él mismo,
              consientes de la importancia de tener creyentes afines y espirituales que trabajan juntos en la obra.
              Después de mucha oración y el estudio, llegamos a la conclusión que guardar la palabra de Dios en su pura forma fue el trabajo del sacerdocio de creyentes,
              obrando juntos al unísono para leer y releer de nuevo la palabra de Dios una y otra vez.
            </p>
            <p>
              Como Iglesia, reunimos todas las Biblias protestantes, españolas y antiguas en que podríamos conseguir trabajar juntos para ir verso por verso por éstas muchas traducciones españolas diferentes. 
            </p>
            <p>La siguiente es una lista de sólo parte de los muchos textos diferentes, de Biblias, y de las versiones que utilizamos en este trabajo, comparando versículo por versículo: El Texto Masorético Hebreo y el Texto Recibido Griego:</p>
            <ul>
              <li>1537 porciones de las escrituras traducidas por Juan de Valdés (incluye: Mateo, Romanos, y 1 Corintios. También Salmos capítulos 1-41)</li>
              <li>1543 Nuevo Testamento de Francisco de Enzinas</li>
              <li>1556 Nuevo Testamento de Juan Pérez de Pineda</li>
              <li>1553 Antiguo Testamento de Ferrara</li>
              <li>1553 Antiguo Testamento de Ferrara</li>
              <li>1569 Biblia de Casiodoro de Reina</li>
              <li>1602 Revisión de Cipriano de Valera</li>
              <li>1817 Valera Revisión</li>
              <li>1831 Valera Revisión</li>
              <li>1862 Valera Revisión</li>
              <li>1865 American Bible Society Revisión</li>
              <li>1869 Valera Revisión</li>
              <li>1909 Antigua Biblia</li>
              <li>199? Nuevo Testamento de Bernardo McVey traducido directamente del Rey Jaime en inglés</li>
              <li>1611 King James (Rey Jaime) en Inglés</li>
              <li>La Biblia Portuguesa revisada y publicada por la Sociedad Bíblica Trinitaria</li>
            </ul>
            <p>
              Armados con estos y muchos más, comparamos versículo por versículo con la Rey Jaime y la original 1569 y 1602 de ambos Reina y Valera.
            </p>
            <p>Nuestra meta fue que quedara lo más posible a la original revisión de 1602 de Valera, mientras que el texto leía con los antiguos textos castellanos protestantes, cambiando el texto solamente cuando era necesario, para sacar lecturas católicas de la Vulgata, reparando los errores doctrinales, y cambiando palabras tan adecuadas que hoy no se entienden.</p>
            <p>Nuestro deseo fue producir una Biblia libre de todos los textos críticos y católicos mientras que leyera con la original 1602 de Valera lo más posible. (Así el nombre VALERA 1602 Purificada). También, nuestro enfoque principal fue utilizar el hebreo y el griego atrás de la Rey Jaime y asegurarnos que todas las palabras en español fueron traducidas correctamente. Nuestro primer Nuevo Testamento fue impreso en Guatemala en 1999, pero teniendo muchas faltas de ortografía, tuvo que ser impreso otra vez en una segunda edición con correcciones en 2002, ¡Exactamente 400 años después de la 1602 revisión de Valera!.</p>
          </div>
          <div className="col-md-12">
            <h4>Lo que diferencia a la Biblia Valera 1602 Purificada</h4>
            <p>
              De todas las denominaciones hispanohablantes evangélicas, sólo los bautistas independientes parecen ser los que están mostrando a otros los problemas en las biblias españolas, y entonces sólo un número muy pequeño de ellos desea arreglarla, como todas las otras denominaciones parecen estar contentos con su liberal, corrupta, y ecuménica revisión de 1960.
            </p>
            <p>
              Pero no todos los bautistas independientes desean una biblia más pura. Hay todavía un grupo grande de los que están inexorables en defender el texto de la 1960, imprimiendo aún su propia versión de la 1960, llamándola "La Edición Bautista Fundamental". Pero enfrentado con la montaña de evidencia que la 1960 es muy pro-católica, pro-crítica, y pro-ecuménica, muchos fundamentalistas y evangélicos comenzaron a despertarse y hacer preguntas. Pero otros ni saben dónde girar. Pero ellos encuentran que éstos no son perfectos, porque no son revisiones bien hechas ni completas. Y así, preguntan, "¿Dónde está la Biblia hispana correcta, la 1602 Purificada (IBBG) quita completamente todas las lecturas críticas del texto, pero en el mismo tiempo retiene la belleza verdadera de la Valera, sin ser una Biblia española híbrida mezclado con palabras católicas como Verbo?" Hay sólo una Biblia en español que vuelve a la fuente original La Valera 1602 siguiéndolo tan cerca como posible, al procurar retener la belleza del idioma castellano, y al asegurarse de preservar las lecturas de las Biblias castellanas antiguas. Esa versión se llama La Valera 1602 Purificada (IBBG).
            </p>
            <p>Si deseas obtener más información respecto a la biblia Valera 1602 Purificada (IBBG), envíanos un correo a la siguiente dirección <strong>valera1602p@maravillosagracia.com</strong>.</p>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Bible
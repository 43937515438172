import React from 'react'
const Nosotros = () => (
  <>
  <div style={{height: "50px"}}></div>
  <div className="site-section" >
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h3>Nuestro Pastor</h3>
          <p>
            Nació en el pueblo de Nazareno Durango, sus padres fueron salvos cuando él era muy pequeño;
            dándole Dios la bendición de crecer en un hogar cristiano y a la edad de quince años le entregó su vida a Cristo.
            En 1988 llegó a Monterrey, N.L para comenzar sus estudios en el Instituto Bíblico, en ese mismo año después de un gran huracán,
            inició su labor ministerial. En Mayo del siguiente año, inició formalmente lo que hoy es la Iglesia Bautista Bíblica de la Gracia,
            la cual por la gracia de Dios ha pastoreado por 30 años. El 7 de Julio de 1990, se casó y Dios le dió la bendición
            a él y a su esposa de procrear 8 hijos. Entre los ministerios que ha emprendido se encuentra la dirección
            del proyecto de la Biblia Valera 1602 Purificada IBBG, esto por más de 20 años. También conduce y predica
            en el Programa de Radio "Maravillosa Gracia" por más de 15 años. Dios le ha dado la bendición de predicar en México,
            Centro y Sur América.
          </p>
        </div>
        <div className="col-md-6">
          <img src="https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/assets%2Fpastor.png?alt=media&token=e7f84395-76ea-4f5c-9648-39d5a9fa9cf1" alt="" className="img-fluid shadow-image"/>
        </div>
      </div>
    </div>
  </div>
  </>
)

export default Nosotros
import React from 'react'
import Slider from './slider'
import About from './about'
const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
]
let month = MONTHS[new Date().getMonth()]
let year = new Date().getFullYear()
const Main = (props) => (
  <>
    <Slider month={month} year={year} settings={props.settings}/>
    <About />
  </>
)

export default Main;
import React from 'react'

const Location = (props) => (
  <div className="py-5 quick-contact-info footer">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div>
            <h2><span className="icon-room"></span> Dirección</h2>
            <p className="mb-0"> San Felipe #111 Col. San Francisco, Santa Catarina, N.L</p>
          </div>
        </div>
        <div className="col-md-4">
          <div>
            <h2><span className="icon-clock-o"></span> Horario de Servicios</h2>
            <p className="mb-0">
               <br/>
              Miércoles: <br/>
              7:00pm Servicio General <br/>
              Sabados: <br/>
              5:00pm Reunión de Jóvenes <br/>
              Domingos: <br/>
              9:00am Servicio de Oración <br/>
              10:00am Escuela Dominical <br/>
              11:00am Servicio General <br/>
              7:00pm Servicio General <br/>
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <h2><span className="icon-comments"></span> Contácto</h2>
          <p className="mb-0">
          <i className="fas fa-envelope"></i> gracia209@hotmail.com <br/>
            <i className="fas fa-phone-volume"></i> 81 83 16 4210 <br/>
            <i className="fab fa-facebook-f"></i> <a href="https://www.facebook.com/Maravillosa-Gracia-423414691032100" style={{color: 'rgba(255, 255, 255, 0.5)'}} target="_blank"  rel="noopener noreferrer">Maravillosa Gracia</a> <br/>
            <i className="fab fa-youtube"></i> <a href="https://www.youtube.com/channel/UCwEnsI9SPMe9MCFYNzgGb8g" style={{color: 'rgba(255, 255, 255, 0.5)'}} target="_blank"  rel="noopener noreferrer">Maravillosa Gracia</a>
          </p>
        </div>
        <div className="col-md-12">
          <p className="text-center pt-5">&copy; {props.year} Iglesia Bautista Bíblica de la Gracia</p>
        </div>
      </div>
    </div>
  </div>
)

export default Location
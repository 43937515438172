import React from 'react'
import {Link} from 'react-router-dom'
const Resources = () => (
  <>
    <div style={{height: '50px'}}></div>
    <div className="site-section">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <Link to='/planes'>
              <div className="card shadow-card">
                <img src="https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/assets%2Fbible%20(1).jpg?alt=media&token=1d107f46-7e8b-478e-9e1b-29516cbcc1d8" alt="" className="card-img-top" height="200"/>
                <div className="card-body">
                  <h5 className="card-title text-center">Planes de lectura</h5>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <a href="/">
              <div className="card shadow-card">
                <img src="https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/assets%2Fpreacher.jpg?alt=media&token=b8700bfe-4672-422d-879c-87c06ab519f7" alt="" className="card-img-top" height="200" />
                <div className="card-body">
                  <h5 className="card-title text-center">Predicaciones</h5>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <Link>
              <div className="card shadow-card">
                <img src="https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/assets%2Fradio.jpg?alt=media&token=04bca590-ffd9-4268-81ef-9d4a84e9f856" alt="" className="card-img-top" height="200" />
                <div className="card-body">
                  <h5 className="card-title text-center">Programas de Radio</h5>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 pt-5">
            <Link>
              <div className="card shadow-card">
              <img src="https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/assets%2Fbooks.jpg?alt=media&token=70cca908-0208-4ae8-8831-f49d2d296f8f" alt="" className="card-img-top" height="200" />
                <div className="card-body">
                  <h5 className="card-title text-center">Articulos Descargables</h5>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Resources
import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../images/logo.png';
const Navbar = () => (
  <>
    <div className="site-mobile-menu">
      <div className="site-mobile-menu-header">
        <div className="site-mobile-menu-close mt-3">
          <span className="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div className="site-mobile-menu-body"></div>
    </div>
    <div className="site-navbar-wrap js-site-navbar">
      <div className="container">
        <div className="site-navbar">
          <div className="py-1">
            <div className="row align-items-center">
              <div className="col-2">
                <h2 className="mb-0 site-logo">
                  <a href="/">
                    <img src={logo} alt="logo" width="80"/>
                  </a>
                </h2>
              </div>
              <div className="col-10">
                <nav className="site-navigation text-right" role="navigation">
                  <div className="container">
                    <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3"><a href="/" className="site-menu-toggle js-menu-toggle text-black"><span className="icon-menu h3 navbar--icon"></span></a></div>

                    <ul className="site-menu js-clone-nav d-none d-lg-block">
                      <li>
                        <Link to='/nosotros'>Nosotros</Link>
                      </li>
                      <li>
                        <Link to="/radio">Radio</Link>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/channel/UCwEnsI9SPMe9MCFYNzgGb8g/videos" target="_blank"  rel="noopener noreferrer">Predicaciones</a>
                      </li>
                      <li>
                        <Link to='/proyecto-biblia'>Proyecto de la Biblia</Link>
                      </li>
                      <li>
                        <Link to="/articulos">Articulos</Link>
                      </li>
                      <li>
                        <Link to='/tienda'>Tienda</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)


export default Navbar;
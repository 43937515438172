import React, {Component, useState} from 'react'
import { Modal, Button } from 'react-bootstrap'

function ModalContact() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" onClick={handleShow} >Comprar</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Para pedidos en México, comuniquese a los siguientes números por WhatsApp:</p>
          <ul className="contact-numbers-list">
            <li key="1"><i className="fab fa-whatsapp"></i> 8180 12 4921</li>
            <li key="2"><i className="fab fa-whatsapp"></i> 8110 44 2576</li>
          </ul>
          <p>O bien envíenos un mail a <strong>gracia209@hotmail.com</strong> con los datos de la Biblia que desea adquirir.</p>
        </Modal.Body>
      </Modal>
    </>
  )
}


class Store extends Component {
  componentDidMount() {
    console.log("Montando", this.props.data)
  }
  render() {
    console.log("Renderizando", this.props.data)
    return(
      <>
        <div style={{height: '50px'}}></div>
        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Tienda</h2>
              </div>
              {this.props.data.map( (item, key) => (
                <div className="col-lg-3 col-md-3 mb-3 align-items-stretch" key={key}>
                  <div className="card h-100">
                    <img src={item[1].image} alt="" className="card-img-top" height="450" style={{height: "18vw"}} />
                    <div className="card-body">
                      <h5 className="card-title">{item[1].title}</h5>
                      <h6><span className="text-danger">${item[1].price}</span></h6>
                      <p><strong className={`${item[1].status ? 'text-success' : 'text-danger' }`}>{`${item[1].status ? 'En Stock' : 'Sin Stock'}`}</strong></p>
                      <ModalContact />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Store
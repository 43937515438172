import React from 'react'
const About = () => (
  <div className="site-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 mb-5 mb-md-0">
          <div className="img-border">
            <img src="https://firebasestorage.googleapis.com/v0/b/maravillosagracia-c1683.appspot.com/o/assets%2Fchurch.jpg?alt=media&token=265ebf9d-34f8-416d-be9f-c5e374640e2a" alt="" className="img-fluid"/>
          </div>
        </div>
        <div className="col-md-5 ml-auto">
          <h2 className="h2 mb-3">Nuestra Iglesia</h2>
          <p className="mb-4">
            Esperamos que se sienta como en su casa al adorar juntos a Dios. Si usted busca una Iglesia que pueda llamar "Su Iglesia",
            queremos que sepa que aqui hay un lugar especial para usted
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default About
import React from 'react'

const RadioInfo = () => (
  <>
    <div style={{height: '50px'}}></div>
    <div className="site-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>Radio</h2>
          </div>
          <div className="col-md-12">
            <p>
              El programa de radio Maravillosa Gracia es un ministerio de la Iglesia Bautista Biblica de la Gracia,
              que ha prevalecido por mas de 20 años con un único fin, alcanzar a otras personas para Cristo.
            </p>
            <p>
              Puede sintonizarnos a través de la emisora <strong>XEMR 1140 AM</strong> o bien a través de la página
              <a href="https://radioesperanza.mx" target="_blank" rel="noopener noreferrer"> Radio Esperanza </a>
              en los siguientes horarios:
            </p>
            <ul>
              <li>Lunes: 8:05am-8:55am</li>
              <li>Martes: 5:05pm-6:00pm</li>
              <li>Miércoles: 8:05am-8:55am</li>
              <li>Jueves: 5:05pm-6:00pm</li>
              <li>Viernes: 8:05am-8:55am</li>
            </ul>
            <p>Teléfonos de Cabina:</p>
            <ul className="contact-numbers-list">
              <li><i className="fas fa-phone-alt" aria-hidden="true"></i> 813 428 6792</li>
              <li><i className="fas fa-phone-alt" aria-hidden="true"></i> 813 428 6793</li>
            </ul>
            <p>Mensajes de Texto:</p>
            <ul className="contact-numbers-list">
              <li><i className="fas fa-phone-alt" aria-hidden="true"></i> 8181 80 1101</li>
            </ul>
            <p>WhatsApp:</p>
            <ul className="contact-numbers-list">
            <li><i className="fab fa-whatsapp-square"></i> 8110 44 2576</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
)
export default RadioInfo